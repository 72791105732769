<template>
  <b-container>
    <b-card no-body>
      <div class="rounded px-3 py-2" style="background-color: rgba(0, 0, 0, 0.05)">
        <issues-sidebar />
      </div>
    </b-card>
  </b-container>
</template>

<script>
import IssuesSidebar from '@/views/Issues/IssuesSidebar.vue'

export default {
  name: 'IssuesFocus',
  components: {
    IssuesSidebar,
  },
}
</script>
